import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';

const Heading = ({ data, className }) => {
  const { text } = data;

  return (
    <Section className={cn('heading-block', [className])}>
      <Container>
        <Row>
          <Column col="12" lg="10" className="offset-lg-1">
            <Text tag="h2" className="headline-1">
              {text}
            </Text>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Heading.defaultProps = {
  className: '',
};

Heading.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Heading;
